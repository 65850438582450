// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper 
{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00468D;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loader 
{
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #Fff;
    animation: loader 2s infinite ease;
}

.loader-inner 
{
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    animation: loader-inner 2s infinite ease-in;
}

.loader--hidden 
{
    opacity: 0;
    visibility: hidden;
}

@keyframes loader 
{
  0% { transform: rotate(0deg);}
  25% { transform: rotate(180deg);}
  50% { transform: rotate(180deg);}
  75% { transform: rotate(360deg);}
  100% { transform: rotate(360deg);}
}

@keyframes loader-inner 
{
  0% { height: 0%;}
  25% { height: 0%;}
  50% { height: 100%;}
  75% { height: 100%;}
  100% { height: 0%;}
}`, "",{"version":3,"sources":["webpack://./src/components/loading-screen.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,kCAAkC;AACtC;;AAEA;;IAEI,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,sBAAsB;IACtB,2CAA2C;AAC/C;;AAEA;;IAEI,UAAU;IACV,kBAAkB;AACtB;;AAEA;;EAEE,KAAK,uBAAuB,CAAC;EAC7B,MAAM,yBAAyB,CAAC;EAChC,MAAM,yBAAyB,CAAC;EAChC,MAAM,yBAAyB,CAAC;EAChC,OAAO,yBAAyB,CAAC;AACnC;;AAEA;;EAEE,KAAK,UAAU,CAAC;EAChB,MAAM,UAAU,CAAC;EACjB,MAAM,YAAY,CAAC;EACnB,MAAM,YAAY,CAAC;EACnB,OAAO,UAAU,CAAC;AACpB","sourcesContent":[".loader-wrapper \n{\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    background-color: #00468D;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 999;\n}\n\n.loader \n{\n    width: 30px;\n    height: 30px;\n    position: relative;\n    border: 4px solid #Fff;\n    animation: loader 2s infinite ease;\n}\n\n.loader-inner \n{\n    vertical-align: top;\n    display: inline-block;\n    width: 100%;\n    background-color: #fff;\n    animation: loader-inner 2s infinite ease-in;\n}\n\n.loader--hidden \n{\n    opacity: 0;\n    visibility: hidden;\n}\n\n@keyframes loader \n{\n  0% { transform: rotate(0deg);}\n  25% { transform: rotate(180deg);}\n  50% { transform: rotate(180deg);}\n  75% { transform: rotate(360deg);}\n  100% { transform: rotate(360deg);}\n}\n\n@keyframes loader-inner \n{\n  0% { height: 0%;}\n  25% { height: 0%;}\n  50% { height: 100%;}\n  75% { height: 100%;}\n  100% { height: 0%;}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/tailwindcss/base.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/tailwindcss/components.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/tailwindcss/utilities.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-link {
  color: #ecaa1e;
  text-decoration: underline;
}

.custom-link:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/explore-services/LibraryResources.css"],"names":[],"mappings":"AAIA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["@import \"tailwindcss/base\";\n@import \"tailwindcss/components\";\n@import \"tailwindcss/utilities\";\n\n.custom-link {\n  color: #ecaa1e;\n  text-decoration: underline;\n}\n\n.custom-link:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

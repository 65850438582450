// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer 
{
    background-color: var(--secondary-color);
    color: white;
    text-align: center;
    font-size: 14px;
    position: absolute;
    width: 100%;
    padding : 30px 10%;
}

.footer-text
{
    margin : 20px 10px;
}

.footer-container
{
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.footer-container a
{
    color : white;
    margin : 10px 20px;
    text-decoration: none;
}

.footer-container a i
{
    text-align: center;
    width: 100%;
}

#tech-support
{
    color : var(--secondary-color);
    transition : all 0.3s ease-in-out;
    font-weight: 500;
    background-color: var(--accent-color);
    padding : 10px 12px;
    border-radius : 15px;
}

#tech-support:hover
{
    color: white;
    background-color: var(--dark-secondary-color);
}

@media all and (max-width : 480px)
{
    footer
    {
        padding : 20px 10px;
        font-size : 12px;
    }

    .footer-container a i
    {
        font-size: 18px;
        margin-bottom: 15px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/footer.css"],"names":[],"mappings":"AAAA;;IAEI,wCAAwC;IACxC,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,kBAAkB;AACtB;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;IACb,qBAAqB;IACrB,uBAAuB;IACvB,eAAe;IACf,mBAAmB;AACvB;;AAEA;;IAEI,aAAa;IACb,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;;IAEI,kBAAkB;IAClB,WAAW;AACf;;AAEA;;IAEI,8BAA8B;IAC9B,iCAAiC;IACjC,gBAAgB;IAChB,qCAAqC;IACrC,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;;IAEI,YAAY;IACZ,6CAA6C;AACjD;;AAEA;;IAEI;;QAEI,mBAAmB;QACnB,gBAAgB;IACpB;;IAEA;;QAEI,eAAe;QACf,mBAAmB;IACvB;AACJ","sourcesContent":["footer \n{\n    background-color: var(--secondary-color);\n    color: white;\n    text-align: center;\n    font-size: 14px;\n    position: absolute;\n    width: 100%;\n    padding : 30px 10%;\n}\n\n.footer-text\n{\n    margin : 20px 10px;\n}\n\n.footer-container\n{\n    display: flex;\n    align-content: center;\n    justify-content: center;\n    flex-wrap: wrap;\n    align-items: center;\n}\n\n.footer-container a\n{\n    color : white;\n    margin : 10px 20px;\n    text-decoration: none;\n}\n\n.footer-container a i\n{\n    text-align: center;\n    width: 100%;\n}\n\n#tech-support\n{\n    color : var(--secondary-color);\n    transition : all 0.3s ease-in-out;\n    font-weight: 500;\n    background-color: var(--accent-color);\n    padding : 10px 12px;\n    border-radius : 15px;\n}\n\n#tech-support:hover\n{\n    color: white;\n    background-color: var(--dark-secondary-color);\n}\n\n@media all and (max-width : 480px)\n{\n    footer\n    {\n        padding : 20px 10px;\n        font-size : 12px;\n    }\n\n    .footer-container a i\n    {\n        font-size: 18px;\n        margin-bottom: 15px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
